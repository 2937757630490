$sizes: (
  25: 25%,
  50: 50%,
  33: 33%,
  60: 60%,
  66: 66%,
  70: 70%,
  75: 75%,
  100: 100%,
  "15p": 15%,
  "20p": 20%,
  "25p": 25%,
  "33p": 33%,
  "50p": 50%,
  "75p": 75%,
  "100p": 100%,
  60px: 60px,
  80px: 80px,
  100px: 100px,
  150px: 150px,
  210px: 210px,
  auto: auto
);

$spacer: 4rem !default;
$spacers: () !default;

$spacers: map-merge(
  (
    0: 0px,
    "0x": 2px,
    "1x": 4px,
    1: 8px,
    2: 16px,
    3: 32px,
    4: 64px,
    5: 76px,
    6: 96px,
    7: 152px,
    "15p": 15%,
    "20p": 20%,
    "25p": 25%,
    "33p": 33%,
    "50p": 50%,
    "75p": 75%,
    "100p": 100%,
  ), 
  $spacers
);
  

$line-height-base: 1;

$container-max-widths: (
  lg: 1024px,
  xl: 1024px
);

$grid-gutter-width: 32px;



@import '~bootstrap/scss/bootstrap';

@each $width, $val in $border-widths {
  @each $pos, $pos-value  in $border-pos {

    @each $breakpoint, $value in $grid-breakpoints {
      @media (min-width: $value) {  
        .border-#{$pos}-#{$breakpoint}-#{$width} {
          border-#{$pos-value}-width: $val!important;
        }  
      }
    }

    .border-#{$pos}-#{$width} {
      border-#{$pos-value}-width: $val;
    }

  }

  .brad-#{$width} {
    border-radius: $val;
  }
  
  .bx-#{$width} {
    border-left-width: $val;
    border-right-width: $val;
  }
  .by-#{$width} {
    border-top-width: $val;
    border-bottom-width: $val;
  }

  .bbox-#{$width}, .border-#{$width} {
    border-width: $val!important;
  }

  
}


// .wp-block {
  @each $size, $w-value in $sizes {
    .w-#{$size} {
      width: $w-value;
    }

    @each $breakpoint, $value in $grid-breakpoints {
      @media (min-width: $value) {  
        .w-#{$breakpoint}-#{$size} {
          width: $w-value !important;
          flex-basis: $w-value !important;
        }  
      }
    }

    
  }


  /*
  * Toolkit specific margins
  * - As most of the content's dynamically built in wordpress, we style by extending 
  * - the styles onto each key content element.
  */
  [data-page="toolkit"] .content {
    h1, h2 {
      @extend .pt-5;
      @extend .m-0;
      @extend .pb-3;
    }
    h4, h3 {
      @extend .mt-3;
      @extend .pb-2;
    }
    
    ul, ol {
      li { @extend .pb-2; }
      @extend .pl-2;
    }

  }

  /** TEMP!! */
  .page-view--hero-bg {
    h1, h2 {
      @extend .px-lg-2;
    }
  }

// }