@use "~bootstrap/scss/bootstrap";


@mixin mobile {
  @media screen and (max-width: map-get($grid-breakpoints, lg)) {
    @content
  }
};

@mixin tablet {
  @media screen and (max-width: map-get($grid-breakpoints, lg)) and (min-width: map-get($grid-breakpoints, md)) {
    @content
  }
};


@mixin not-desktop {
  @media screen and (max-width: map-get($grid-breakpoints, lg)) { 
    @content;
  }
};

@mixin desktop {
  @media screen and (min-width: map-get($grid-breakpoints, lg)) {
    @content;
  }
};