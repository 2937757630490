@use 'color';
@import url('https://fonts.googleapis.com/css?family=Libre+Baskerville:100,200,300,400,700|Public+Sans:100,200,300,400,500,600,700,800,900|Space+Mono:400,700&display=swap');
@import 'marginalia';
@import 'media';


@function spacer($size) {
  @return map-get($spacers, $size);
}

$sans-serif: 'Public Sans', sans-serif;
$serif: 'Libre Baskerville', serif;
$monospace: 'Space Mono', monospace;
$bold: 800;
$semibold: 600;

/* FONT SIZES*/

$font-sizes: (
  0: 12px,
  0x: 14px,
  1: 16px,
  2: 26px,
  3: 26px,
  4: 32px,
  5: 38px,
);

$line-heights : (
  0: 16px,
  0x: 20px,
  1: 18px,
  2: 32px, 
  3: 32px, 
  4: 38px, 
  5: 48px,
);


// .wp-block {

h1, h2, h3, h4, h5, p, strong, em, a {
  margin: 0; 
  margin-bottom: 0!important;
  color: inherit;
}



.is-serif {
  font-family: $serif !important;
}

.is-sans-serif {
  font-family: $sans-serif !important;
}

.is-monospace {
  font-family: $monospace !important;
}




.is-serif {
  font-family: $serif;
}

.is-sansserif {
  font-family: $sans-serif;
}

.is-monospace {
  font-family: $monospace;
}

@each $level, $font-size in $font-sizes {
  .font-#{$level} {
    font-size: $font-size;
    line-height: map-get($line-heights, $level);
  }

  @each $bp, $break-width  in $grid-breakpoints {
    .font-#{$bp}-#{$level} {
      font-size: $font-size;
      line-height: map-get($line-heights, $level);
    }
  }

  .letter-spacing-#{$level} {
    letter-spacing: #{$level}px;
  }
}


code {
  @extend .bg-white;
  @extend .color-pop-1;
  @extend .is-monospace;
}

// Typographies
.t-nav {
  font-family: $monospace;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  text-transform: uppercase;

  @include mobile {
    font-family: $monospace;
    font-style: normal;
    font-weight: bold;
    font-size: 19px;
    line-height: 28px;
    text-transform: uppercase;
  }
}

.t-statement {
  font-family: $monospace;
  font-style: normal;
  font-weight: normal;
  font-size: 65px;
  line-height: 78px;
  @include mobile { 
    font-size: 28px!important;
    line-height: 40px!important;
  }
}

.t-subtitle {
  font-family: $sans-serif;
  font-size: 25px;
  line-height: 1.2;
  font-weight: bold;
  @include mobile { 
    font-size: 18px!important;
    line-height: 21px!important;
  }
}

.t-h1 {
  font-family: $monospace;
  font-style: normal;
  font-weight: normal;
  font-size: 65px;
  line-height: 78px;

  @extend .mt-n2;

  @include mobile {
    font-size: 30px!important;
    line-height: 40px!important;
  }
}

.t-h2 {
  font-family: $sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 25px!important;
  line-height: 29px!important;
  letter-spacing: 1px;
  text-transform: uppercase;

  @include mobile { 
    font-size: 20px!important;
    line-height: 23px!important;
  }
}

.t-h3 {
  font-family: $sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 23px;

  @include mobile { 
    font-size: 20px!important;
    line-height: 23px!important;
    letter-spacing: 1px!important;
  }
}

.t-h4 {
  font-family: $serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px; 
}

.t-lead, .t-lead p {
  font-family: $serif;
  font-style: normal;
  font-weight: normal;
  font-size: 30px!important;
  line-height: 50px !important;

  @include mobile { 
    font-size: 20px!important;
    line-height: 32px!important;
  }
}

.t-body {
  font-family: $serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 30px;

  @include mobile { 
    font-size: 16px;
    line-height: 26px;
  }
}

.t-metatag {
  font-family: $monospace;
  font-style: normal;
  font-weight: normal;
  font-size: 11px;
  line-height: 16px;
  text-transform: uppercase;

  @include mobile { 
    font-size: 11px!important;
    line-height: 16px!important;
  }
}

.t-stats {
  font-family: $monospace;
  font-style: normal;
  font-weight: normal;
  font-size: 80px!important;
  line-height: 1!important;

  @include mobile { 
    font-size: 60px!important;
    line-height: 89px!important;
  }
}

.t-stats-caption {
  font-family: $monospace!important;
  font-style: normal;
  font-weight: 700;
  font-size: 25px!important;
  line-height: 37px!important;
  letter-spacing: 1px;

  @include mobile { 
    font-size: 18px!important;
    line-height: 27px!important;
  }
}

.t-bignumber {
  font-family: $monospace;
  font-style: normal;
  font-weight: normal;
  font-size: 80px;
  line-height: 118px;
  letter-spacing: 1px;

  @include mobile { 
    font-size: 45px!important;
    line-height: 67px!important;
  }
}

.t-imgcaption {
  font-family: $sans-serif!important;
  font-style: normal;
  font-weight: normal;
  font-size: 12px!important;
  line-height: 16px!important;

  @include mobile { 
    font-size: 12px!important;
    line-height: 14px!important;
  }
}

.t-captionhead {
  font-family: $sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 25px;

  @include mobile { 
    font-size: 18px!important;
    line-height: 21px!important;
  }
}

.t-captionbody {
  font-family: $sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;

  @include mobile { 
    font-size: 16px;
    line-height: 19px;
  }
}

.t-quote {
  font-family: $monospace;
  font-style: normal;
  font-weight: normal;
  font-size: 26px;
  line-height: 50px;

  @include mobile { 
    font-size: 20px!important;
    line-height: 30px!important;
  }
}

.t-quote-sign:after {
  content: "“";
  display: block;
  font-size: 100px;
  line-height: 10px;
  color: map-get($colors, pop-2);
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
}

.t-quoteattrib {
  font-family: $sans-serif;
  font-style: normal!important;
  font-weight: bold;
  font-size: 18px!important;
  text-transform: none!important;
  letter-spacing: 1px;

  @include mobile { 
    font-size: 18px!important;
    line-height: 21px!important;
  }
}

.t-pullquote {
  font-family: $monospace;
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  line-height: 40px;

  @include mobile { 
    font-size: 20px!important;
    line-height: 30px!important;
  }
}

.t-metaheader {
  font-family: $monospace;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  text-transform: uppercase;
  @include mobile { 
    font-size: 12px!important;
    line-height: 14px!important;
  }
}

.t-casesubtitle {
  font-family: $sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 35px;

  @include mobile { 
    font-size: 18px!important;
    line-height: 21px!important;
  }
}

.t-hptext {
  font-family: $serif;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 45px;
}

.t-link {
  font-family: $sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 26px;

  @include mobile { 
    font-size: 18px!important;
    line-height: 24px!important;
  }
}

.t-tag {
  font-family: $monospace;
  font-size: 14px;
  letter-spacing: 0.25px;
  line-height: 18px;
  font-style: normal;

  @include mobile { 
    font-size: 11px!important;
    line-height: 16px!important;
  }
}

/* CONVERSIONS */
.typo-lead {
  @extend .t-lead;
}

h2 {
  @extend .t-h2;
  @extend .font-weight-bold;
}

p {
  @extend .t-body;
}


.text-transform-none {
  text-transform: none!important;
}
// }

[data-type=percentage]:after {
  content: '%';
}

sup {
  font-family: $monospace;
  color: map-get($colors, pop-1);
}