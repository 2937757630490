$colors: (
  black: #000000,
  white: #FFFFFF,
  base-dark: #042D36,
  pop-1: #E1004B,
  pop-2: #0BB7C2,
  gray-light: #F6F6F6,
  gray-medium: #E1E1E1,
);

$border-pos: (
  t: "top",
  r: "right",
  b: "bottom", 
  l: "left"
);

$border-widths: (
  0: 0,
  1: 1px, 
  2: 2px, 
  3: 3px, 
  4: 4px, 
  5: 5px,
  10: 10px,
  20: 20px,
  100p: 100%,
  round: 100px
);

$states: (
  "active",  
  "hover",
  "focus"
);
/* RENDERING OF COLOR AREA*/
// .wp-block {

@each $color, $value in $colors {

  .bg-#{$color} {
    background-color: $value;
  }

  .bg-#{$color}-translucent {
    @if $color == white {
      background-color: rgba(255,255,255,0.85);
    } @else if $color == black {
      background-color: rgba(0,0,0,0.85);
    } @else {
      background-color: #{$value}BB;
    }
  }

  .color-#{$color} {
    color: $value !important;

    a {
      color: $value !important;

      &:hover {
        color: inherit;
      }
    }
  }

 
  

  @each $state in $states {
    .#{$state}-#{$color}:#{$state} {
      color: $value !important;
    }
    .#{$state}-#{$color}-bg:#{$state} {
      background-color: $value!important;
    }
    .#{$state}-#{$color}-border:#{$state} {
      border: solid $value!important;
    }

    .animated-block:#{$state} {
      .#{$state}-#{$color} {
        color: $value !important;
      }
      .#{$state}-#{$color}-bg {
        background-color: $value!important;
      }
      .#{$state}-#{$color}-border {
        border: solid $value!important;
      }
    }
  }
  

  .with-line-#{$color} {
    position: relative;

    &:before {
      position: absolute;
      height: 5px;
      width: 75px;
      content: '';
      background-color: $value;
      top: -25px;
      left: 0px;
    }
  }

}




/* RENDERING BORDERS */

@each $color, $value in $colors {
  @each $pos, $pos-value in $border-pos {
    .border-#{$pos}-#{$color} {
      border-#{$pos-value}-style: solid;
      border-#{$pos-value}-color: $value!important;
    }
  }

  .border-#{$color} {
    border-style: solid;
    border-color: $value;
    border-width: 0;
  }

  .by-#{$color} {
    border-style: solid;
    border-top-color:  $value;
    border-bottom-color:  $value;
  }

  .bx-#{$color} {
    border-style: solid;
    border-left-color:  $value;
    border-right-color:  $value;
  }
  
}



.anim-border {
  transition: all 0.25s;
  @each $width, $val in $border-widths {
    @each $state in $states {
      @each $pos, $pos-value in $border-pos {
        &.border-#{$pos}-#{$state}-#{$width}:#{$state} {
          border-#{$pos-value}-width: $val!important;
        }
      }

      &.border-#{$state}-#{$width}:#{$state} {
        border-width: $val;
      }
    }
  }
}


.anim-opacity {
  transition: all 0.25s;
  &.opacity-show-default {
    opacity: 1;
  }
  &.opacity-hide-default { 
    opacity: 0;
  }

  @each $state in $states {
    &.opacity-show-#{$state}:#{$state} {
      opacity: 1;
    }
    &.opacity-hide-#{$state}:#{$state} {
      opacity: 0;
    }
  }
}

@each $state in $states {
  .animated-block:#{$state} {
    .opacity-show-#{$state} {
      opacity: 1;
    }
    .opacity-hide-#{$state} {
      opacity: 0;
    }
  }
}
// }

/* animate shadow */
.anim-shadow {
  transition: all 0.25s;
  @each $state in $states {
    &:#{$state} {
      box-shadow: 0 0 10px rgba(0,0,0,0.3)!important;
    }
  }
}

.blend-mode-multiply {
  mix-blend-mode: multiply;
}
