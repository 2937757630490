@use 'color';
@import 'typographies';


@mixin sprite {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
}

@mixin overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 2;
  content: '';
}

// .wp-block {
button {
  background: none;
  border: none;
}

.hideable {
  max-height: 200px;
  transition: max-height 0.5s, padding 0.5s;


  &.hide {
    max-height: 0 !important;
    padding: 0 !important;
  }
}



/* Table of contents*/
ol.toc {
  & > li {
    text-indent: -12px;
    counter-increment: list;
    list-style-type: none;
    
    &::before {
      content: counter(list) ".";
      font-weight: $semibold;
      padding-right: 16px;
    }
    ul { display: none; }

    &[data-active="true"]{
      &::before {
        color: map-get($colors, pop-1) !important;
      }
      ul { 
        display: block; 
        li.subsec { 
          padding: 0; text-indent: 0;
          position: relative;
          margin-left: 20px;
          &[data-active="true"]:before {
            content: '';
            @include sprite;
            background-image: url('../../image/pink-arrow-rt.svg');
            width: 10px;
            height: 10px;
            left: -32px;
            top: 13px;
            text-indent: 0;
          }
        }
        
        
      }
    }

  }

  &:not(.expanded) {
    @include not-desktop {
      li {
        padding-bottom: 0!important;
        padding-top: 0!important;
      }
    }
  }

  @media (min-width: map-get($grid-breakpoints, lg)) {
    li { 

      ul { 
        display: block;
        max-height: 0px; 
        overflow: hidden;
        opacity: 0;
        transition: max-height 0.25s, opacity 0.25s, overflow 0.5s;
  
        li {
          text-indent: 0;
        }
      }

      &[data-active="true"] ul {
        max-height: 500px;
        opacity: 1;
        overflow: visible;
      }
    }
  }

}

.toolkit-content {
  blockquote.wp-block-quote {
    border-left: 6px solid map-get($colors, gray-medium); 
    padding: 0px 0 0px 20px;
    font-family: $monospace;
    &:before {
      content: '“';
      color: #E1E1E1;
      font-size: 40px;
      height: 0px;
      display: block;
      margin-bottom: 40px;
      margin-left: -7px;
    }
    p {
      font-family: $monospace;
      font-size: 20px;
      color: map-get($colors, base-dark);
      font-weight: bold;
    }
    cite {
      font-family: $monospace;
      font-size: 10px;
      text-transform: uppercase;
      font-style: normal;
      color: map-get($colors, base-dark);
    }
    
    @media (max-width: map-get($grid-breakpoints, lg)) {
      border-left-width: 4px;
      &:before {
        font-size: 36px;
        margin-bottom: 25px;
      }
      p {
        font-size: 14px;
      }
      cite {
        font-size: 8px;
      }
    }
  }
}


// Background items
.bg-contain {
  background-size: contain;
  background-position: left center;
  position: relative;
  background-repeat: no-repeat;
}

.bg-cover {
  background-size: cover;
  background-position: center center;
  position: relative;
  background-repeat: no-repeat;

  * {
    position: relative;
    z-index: 10;
  }

  &.bg-size-auto {
    background-size: auto;
  }
  &.bg-cover-dark::before {
    @include overlay;
    background-color: rgba(0,0,0,0.6);
  }

  &.bg-cover-light::before {
    @include overlay;
    background-color: rgba(255,255,255,0.1);
  }

  &.bg-cover-gray::after {
    @include overlay;
    background-image: inherit;
    background-size: inherit;
    background-position: inherit;
    filter: grayscale(1);
    z-index: 1;
  }

  &.bg-cover-blue {
    &::before {
      @include overlay;
      background-color: #f2feff;
      opacity: 0.8;
    }

    &::after {
      @include overlay;
      background-image: inherit;
      background-size: inherit;
      background-position: inherit;
      filter: grayscale(1) brightness(1.5);
      z-index: 1;
    }
  }

  &.bg-cover-lightblue {
    &::before {
      @include overlay;
      background-color: rgb(11,183,194);
      opacity: 0.1;
    }

    &.bg-cover-darker::before { 
      opacity: 0.3!important;
    }

    &::after {
      @include overlay;
      background-image: inherit;
      background-size: inherit;
      background-position: inherit;
      filter: grayscale(1);
      z-index: 1;
    }
  }

  &.bg-cover-darkblue::before {
    @include overlay;
    background-color:  map-get($colors, base-dark);
    opacity: 0.7;
  }

}

// Breakpoints additions
@each $breakpoint, $value in $grid-breakpoints {
  @media (min-width: $value) {
    // Square
    .bg-sq-#{$breakpoint} {
      width: 100% !important;
      padding-bottom: 100% !important;
      height: 0!important;
    }

    // 2:1 aspect
    .bg-ar21-#{$breakpoint} {
      width: 100% !important;
      padding-bottom: 50%!important;
      height: 0!important;
    }

    // 3:2 aspect ratio
    .bg-ar32-#{$breakpoint} {
      width: 100%!important;
      padding-bottom: 66%!important;
      height: 0!important;
    }
    // 3:4 aspect
  }
}

.bg-sq {
  width: 100%!important;
  padding-bottom: 100%!important;
  height: 0!important;
}

// 2:1 aspect
.bg-ar21 {
  width: 100%!important;
  padding-bottom: 50%!important;
  height: 0!important;
}

// 3:2 aspect ratio
.bg-ar32 {
  width: 100%!important;
  padding-bottom: 66%!important;
  height: 0!important;
}


// Hero Area
.hero {
  * {
    padding: 0;
    margin: 0;
  }

  &.h-default {
    height: 450px;
    @include mobile {
      height: 200px;
    }
  }
  
  &.h-tall {
    height: 600px;
    @include mobile {
      height: 300px;
    }
  }
  
  &.h-short {
    height: 330px;
    @include mobile {
      height: 200px;
    }
  }
}


// Flex positions
$flex-positions: (
  start: flex-start,
  end: flex-end,
  between: space-between,
  around: space-around,
  center: center
);

@each $breakpoint, $value in $grid-breakpoints {

  .flex-#{$breakpoint}-column {
    flex-direction: column!important;
  }
  .flex-#{$breakpoint}-row {
    flex-direction: row!important;
  }

  .flex-#{$breakpoint}-occupy {
    flex-grow: 1;
    flex-basis: 0;
  }

  // For flex positions 
  @each $position, $p-val in $flex-positions {
    .justify-content-#{$breakpoint}-#{$position} {
      justify-content: $p-val;
    }
    .align-items-#{$breakpoint}-#{$position} {
      align-items: $p-val;
    }
  }
}

.flex-occupy {
  flex-grow: 1;
  flex-basis: 0%;
}

/* heights */ 
$heights: (
  xs: 80px,
  sh: 160px, 
  md: 240px, 
  tl: 320px,
  xt: 540px,
  auto: auto,
);

@each $height, $h-val in $heights {
  @each $breakpoint, $value in $grid-breakpoints {
    @media (min-width: $value) {
      .h-#{$breakpoint}-#{$height} {
        height: $h-val!important;
      }
    }
  }
    .h-#{$height} {
      height: $h-val!important;
    }
}



@include desktop {
  .top-nav-bar {
    height: 100px!important;
  }
  .animated-logo {
    position: relative;
    height: 100px;
    max-height: 85px;
    transition: max-height 0.5s;

    & > span { 
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%)
    }
    .animated-logo-full {
      height: 50px;
      max-height: 50px;
      transition: max-height 0.35s, opacity 0.35s;
      opacity: 1;
    }
    .animated-logo-short {
      height: 50px;
      max-height: 50px  ;
      opacity: 0;
      transition:  max-height 0.35s, opacity 0.35s;
    }

    &.animated {
      max-height: 40px;
      .animated-logo-full {
        max-height: 20px;
        opacity: 0;
      }
      .animated-logo-short {
        max-height: 20px;
        opacity: 1;
      } 
    }
  }
}

@include mobile { 
  .animated-logo-short {
    height: 30px;
  }
}

@include tablet { 
  .animated-logo-short {
    height: 30px;
  }
}

@media (min-width: map-get($grid-breakpoints, xs)) and (max-width: map-get($grid-breakpoints, md)){
  .row > .col,
  .row > [class*="col-"] {
    padding-right: 12px;
    padding-left: 12px;
  }
}
.row {
  width: 100% !important;
  margin-left: 0!important;
  margin-right: 0!important;
}



/*** Attribution Area */
.attribution-area {
  
  table {
    width: 100%;

    strong { 
      white-space: nowrap;
    }
    tr td, tr th {
      @extend .py-md-1;

      &:first-child{
        width: 33%;
      }
      line-height: 1.25;
    }
    @include not-desktop {
      
      tr {
        padding-top: 16px; 
        display: grid;
      }
    }
  }
}

/** Slogan **/
.slogan {
  height: 440px;
  @include mobile {
    height: 280px;
  }
}

.img-500 {
  height: 500px;
  @include mobile {
    height: auto;
    width: 100%;
  }
}

.img-hover-toggle {
  .img-base { display: inline-block; }
  .img-hover { display: none; }

  &:hover {
    .img-base { display: none; }
    .img-hover { display: inline-block; }
  }
}


/* For Section Counters*/
.section-counter-set {
  counter-reset: section-counter;

  .section-counter {
    &:before {
      counter-increment: section-counter;
      content: counter(section-counter);
    }
  }
}

.position-absolute {
  &.a-r-out {
    left: 100%;
  }
  &.a-r-75 {
    left: 75%;
  }
  
  &.a-mid {
    top: 50%;
    transform: translateY(-50%);
  }
}


.sticky {
  @media (max-width: map-get($grid-breakpoints, lg)) {
    position: relative!important;
  }
}

/* img  */
.content {
  h2{
    @extend .t-h2;
    color: map-get($colors, base-dark);
  }
  h3 {
    @extend .pb-2;
    @extend .t-h3;
    @extend .color-base-dark;
    padding-top: 0;
    margin-top: 0;
  }


  p, ul li, ol li {
    @extend .t-body;

    & + h3,
    & + h2,
    & + h4 {
      @extend .pt-3;
    }
  }
}

h2 {
  @extend .mt-0;
  @extend .pt-0;
  @extend .pb-4;
}

p {
  @extend .pb-2;
}

@media (min-width: map-get($grid-breakpoints, lg)) {
  nav.toolkit-sidebar {
    max-width: 320px;
  }
}

sup {
  @extend .is-monospace;
  color: map-get($colors, pop-1);
}
.toolkit-content {
  h1 {
    @extend .is-monospace;
    font-size: 65px;
    @include mobile {
      font-style: normal;
      font-weight: 400;
      font-size: 30px;
    }
  }
  ol li {
    @extend .is-serif;
    font-size: 18px;
    line-height: 30px;
  }
}


// Z-Index areas ... 
.z-top { z-index: 1000; }
.z-mid { z-index: 500; }
.z-bottom { z-index: 0; }
.h-page { height: 450px; }

// Animated underline
.animated-underline, .animated-down-underline {
  position: relative;
  z-index: 1;
  &:after {
    content: '';
    background-color: map-get($colors, 'pop-2');
    position: absolute;
    z-index: -1;
    transition: all 0.25s;
  }
}

.animated-underline {
  &::after {
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    max-height: 10px;
  }
  &:hover::after {
    max-height: 100%;
  }
}

/* Convert elmeents in figures to match the width*/
.wp-block-image img {
  @extend .w-100;
  height: auto;
}

// Profile photos
.bg-photo {
  padding-bottom: 100%;
}


.wp-block-button {
  .wp-block-button__link {
    @extend .is-sansserif;
    @extend .color-base-dark;
    @extend .t-body;
    @extend .animated-underline;
    padding-bottom: 5px;
  }
}

a {
  &:hover {
    @extend .text-decoration-none;
  }
}

.content{
p a, li a {
  border-bottom: solid map-get($colors, pop-2);
  border-bottom-width: 3px;
  transition: all 0.25s;
  color: map-get($colors, base-dark);
  &:hover {
    color: inherit;
    border-bottom-width: 10px;
  }
}}

.bottom { bottom: 0; }
.left { left: 0; }
.right { right: 0; }
.top { top: 0; }


.work-item {
  .work-caption {
    overflow: hidden;
    max-height: 0;
    @extend .py-0;
    transition: all 0.25s;
  }

  &:hover .work-caption {
    @extend .py-1;
    max-height: 50%;
  }

  .bg-cover { &::after, &::before { transition: all 0.25s; }}
  &:hover .bg-cover {
    &::after, &::before {
      opacity: 0;
    }
  }
}


.team-item, .animated-block {
  transition: all 0.4s;
  &:hover {
    .animated-underline {
      // @extend .animated-underline:hover;
    }
    .anim-opacity {
      // @extend .opacity-show-hover:hover;
    }
  }
}

.w-33 { width: 33.33%!important; }
.w-66 { width: 66.66%!important; }
.w-250px { width: 250px!important; }

.line-clamp-5 {
  margin: 0;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}

.float-quote {

  position: absolute;
  left: 66%;
  top: 50%;
  transform: translateY(-50%);
}


.with-ellipsis { 
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 100px;
  display: inline-block;
}

.block---featured-studies.container {
  padding: 0;

}


.anim-group-img {
  width: 250px;
  height: 250px;

  @include mobile {
    width: 200px;
    height: 200px;
  }
  .o-100, .o-66, .o-33 {
    opacity: 0;
  }
  //   transform: translate(0, 0);
  // }

  .anim-bt {
    &.o-100 { --x: 0; --y: 120px; }
    &.o-66 { --x: 0; --y: 90px; }
    &.o-33 { --x: 0; --y: 60px; }
  }

  .anim-brtl {
    &.o-100 { --x: 60px; --y: 120px; }
    &.o-66 { --x: 45px; --y: 90px; }
    &.o-33 { --x: 30px; --y: 60px; }
  }

  .anim-bltr {
    &.o-100 { --x: -120px; --y: 120px; }
    &.o-66 { --x: -90px; --y: 90px; }
    &.o-33 { --x: -60px; --y: 60px; }
  }

  .anim-rl {
    &.o-100 { --x: 180px; --y: -60px; }
    &.o-66 { --x: 135px; --y: -45px; }
    &.o-33 { --x: 90px; --y: -30px; }
  }

  &.activated {
    .o-100 { z-index: 10; animation: moveImage 2s; opacity: 1;}
    .o-66 { z-index: 5;  animation: moveImage 2s; opacity: 1; }
    .o-33 { z-index: 1;  animation: moveImage 2s;  opacity: 1; }
  }
  
}

@keyframes moveImage {
  from   {opacity: 0; transform: translate(var(--x), var(--y))}
  to   {opacity: 1; transform: translate(0, 0)}
}



.home-image {
  width: 400px;
  height: 400px;
  position: relative;
}

.home-image-landscape.home-image {
  height: 300px;
  width: 500px;
  @include mobile {
    height: 200px;
    width: 300px;
  }
}

.home-image-square.home-image {
  width: 330px;
  height: 365px;
  position: absolute;
  top: 260px;
  right: 0;
  z-index: 200;
  
  @include mobile {
    top: 90px;
    width: 180px;
    height: 260px;
    right: 20px;
  }

  @include tablet {
    width: 420px;
    height: 325px;
    top: 110px;
    right: 20px;
  }
}

.home-image-portrait.home-image {
  width: 330px;
  height: 500px;
  margin-top: 170px;
}

.notification-banner * {
  @extend .p-0;
  @extend .m-0;
  @extend .color-white;
  @extend .font-weight-bold;
  @extend .is-sansserif;
  img {
    @extend .mr-1;
  }
}

.with-arrow-after:after {
  content: '';
  width: 45px;
  height: 15px;
  background-image: url('../../image/left-arrow.svg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  display: inline-block;
  margin-left: 10px;
}

.hover-circle:hover {
  cursor: url(../../image/pointer.svg), auto;
}

.hover-bold:hover {
  font-weight: bold;
}

.bg-whole-team {
  background-image: url('../../image/bg-staff.jpg');
}

/** STICKY HEADERS */
.sticky-header {
  position: relative;
}

.sticky-header * {
  /* padding: 10px; */
  margin: 0;
}

.sticky-header .sticky {
  position: fixed;
  top: 70px;

  @include mobile {
    position: relative!important;
  }
}

.sticky-header .sticky.sticky-landing {
  position: absolute;
  top: auto;
  bottom: 0;
}

.sticky {
  width: 240px;
}


.text-decoration-underline {
  text-decoration: underline!important;
}

.tag {
  img { 
    height: 16px;
    margin-right: 4px;
  }
}

// }



footer {
  ul li {
    @extend .pb-1;
  }
}

.header-item > div {
  
  @include mobile {
    display: flex;
    align-items: center;

    h2 {
      margin-top: -15px!important;
      padding-left: 10px;
      white-space: nowrap !important;
    }
  }
}

@include mobile {
  ol.toc:not(.expanded) {
    & > li[data-active=false] {
      visibility: hidden;
      height: 0;
    }

    & > li li {
      display: none;
    }
  }

  .color-xs-pop-1 {
    color: map-get($colors, pop-1) !important;
  }
}

.about-img {
  width: 170px !important;
  height: auto;
}

.cursor-default {
  cursor: default;
}

@include mobile {
  .anim-mobile-hide {
    overflow: hidden;
    max-height: 100px;
    transition: max-height 2s;
  }

  .anim-mobile-hide.hide {
    max-height: 0;
  }
}


@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {  
  /* add your IE10-IE11 css here */   
  .d-flex.flex-lg-column.flex-row.align-items-center {
    display: inline-block !important;
  }

  span[role='img'].w-100.pb-100p {
    width: 150px!important;
    height: 150px!important;
    padding: 0!important;
  }
  span.d-inline-flex, div.d-inline-flex {
    &.w-100 {
      display: inline-block!important;
    }
  }

  .container {
    width: 2000px !important;
  }
}

.content h4 {
  @extend .t-h4;
  @extend .mb-2;
}

@media (min-width: map-get($grid-breakpoints, xs)) and (max-width: map-get($grid-breakpoints, md)){

  .anim-hide {
    max-height: 100px;
    transition: all 2s;
    overflow: hidden;
    &.hide {
      max-height: 0px;
    }
  }

}