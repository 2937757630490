@import 'lib/color';
@import 'lib/layout';
@import 'lib/typographies';
@import 'lib/marginalia';

html, body, #root {
  height: 100%;
  background-color: map-get($colors, 'gray-light')
}
// .wp-block {
  div.page {
    min-height: 100%;
    display: flex;
    flex-direction: column;

    main {
      flex-grow: 1;
    }
  }

  ol, ul {
    @extend .pb-0;
    @extend .pb-lg-2;
    @extend .pl-2;
  }

  ul {
    list-style: square;
  }

  .content { 
    ol, ul {
      li {
        @extend .pb-2;
      }
    }
  }

  .container {
    padding: 0;
  }
// }



div[class^="wp-block-block-lab-"] .block-form {
  background: #F0F0F0!important;
  border: 1px solid!important;
}

.screen-reader-only {
  position: absolute;
  width: 1px;
  clip: rect(0 0 0 0);
  overflow: hidden;
  white-space: nowrap;
}



// Temp space for Project archive filter


.case-study-archive--filters {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  flex-direction: column;
}

.case-study-filter {
  display: flex;
  align-items: center;
  margin: 0 20px;
}

.filter-select {
  width: 600px;
  margin-left: 10px;
  z-index: 1000;
  

  input { 
    caret-color: map-get($colors, pop-1) !important;
  }

  @include mobile {
    width: 95%;
  }
}

.case-study-archive--items {
  margin-top: 30px;
}

.case-study-archive--row {
  display: flex;
}

.block--featured-study {
  /* flex-basis: 25%; */
  position: relative;
}

.case-study--tags {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.case-study--tag {
  color: var(--darkblue);
  border: 1px solid #042d36;

  display: inline-block;
  padding: 4px 10px;
  border-radius: 2px;
  margin: 2px;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100px;
  overflow: hidden;
}

.case-study--tag {
  color: var(--darkblue);
  text-decoration: none;
}




/*** CaseStudyView.js ***/
.case-study {
  counter-reset: case-study-counter;
}

.case-study
.case-study--hero {
  height: calc(60vh + 50px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  filter: sepia(1) hue-rotate(150deg);
  background-size: cover;
  background-position: center center;
  position: relative;
  margin-top: -50px;
}

.case-study
.case-study--hero:before {
  content: '';
  background-color: rgba(0,0,0,0.6);
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.case-study
.case-study--hero h1 {
  margin: 0;
  color: white;
  z-index: 1;
  margin-bottom: 20px;
}

.case-study
.case-study--hero h2 {
  color: white;
  z-index: 1;
  text-transform: none;
  font-weight: 300;
}


.case-study
.case-study--meta {
  /* height: 25vh;
  max-height: 250px; */
  background-color: #042d36;
}

.block--case-study-area {
  display: flex;
}

.block--case-study-area-counter.typo-big-number {
  padding-top: 64px;
  padding-bottom: 16px;
  line-height: 1;
}

.case-study
.block--case-study-area
.block--case-study-area-header {
  /* flex-basis: 20%; */
  /* background-color: var(--lightgray); */
}

.block--case-study-area-header
.block-header {

  display: flex;
  flex-direction: column;
  align-items: flex-start;

}
.block--case-study-area-header
.block-header.sticky
{
  width: calc(20% - 20px);  

}
.block--case-study-area-header
.block-header.sticky h2 {
  max-width: 245px;
}

.block--case-study-area-header
.block-header.sticky-landing {
  width: calc(100% - 20px);
}


.case-study
.block--case-study-area
.block--case-study-area-header
h1:before {
  counter-increment: case-study-counter;
  content: counter(case-study-counter);
}

.case-study
.block--case-study-area
.block--case-study-info {
  /* flex-basis: 80%; */
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
  flex-direction: column;
}

.case-study
.case-study-lead {
  padding: 40px 0;
  width: 100%;
}

.case-study
.case-study-image {
  width: 100%;
}

.case-study-body {
  width: 100%;
}

.case-study
.case-study-body
.case-study-pullquote {
  /* flex-basis: 30%; */
}

.case-study
.block--case-study-area
.block--case-study-info p {
  /* max-width: 900px; */
  color: var(--darkblue);
}

.wp-block-image.alignfull.size-large {
  margin: 0;
}

.wp-block-image.alignfull.size-large img {
  width: 100%;
}

.case-study
.case-study-image-section {
  width: 100%;
}

.case-study
.case-study-image {
  height: 540px;
}

.case-study
.case-study-caption {
  margin: 20px 0;
  border-top: 2px solid rgb(11,183,194);
  width: 100%;
  color: var(--darkblue);
}

.case-study
.case-study-body
.case-study-pullquote {
  display: flex;
  justify-content: center;
  align-items: center;
}


.case-study
.case-study-body
.case-study-pullquote 
blockquote:before{ 
  
}

.case-study
.case-study-body
.case-study-pullquote 
blockquote:before:after {
  
}

.case-study 
.case-study-body {
  display: flex;
  /* max-width: 900px; */
}

.case-study 
.case-study-body 
.case-study-content {
  /* padding: 20px 80px; */
  width: calc(100% - 210px);
}

.case-study--meta {
  display: flex;
  align-items: center;
  justify-content: center;
}

.case-study-tags-topics ul li::before{
  content: '';
  width: 15px;
  height: 15px;
  background-image: url(../../assets/image/tag.png);
  display: inline-block;
  background-size: cover;  
  vertical-align: top;
  margin-right: 3px;
}

.case-study-meta-container {
  display: flex;
    width: 100%;
    max-width: 1024px;
}

.case-study-meta-column {
  flex-basis: 25%;
  padding-top: 40px;
}

.case-study-tags {
  margin: 10px;
  border-top: 1px solid white;
  margin-bottom: 40px;
  
}

.case-study-tags h5 {
  color: white;
  margin: 0;
  font-family: var(--monospace);
  text-transform: uppercase;
  font-size: 14px;
}

.case-study-tags-list ul {
  padding: 0;
  margin: 0;
  margin-top: 10px;
  list-style: none;
}

.case-study-tags-list ul li {
  background-color: white;
  border-radius: 4px;
  display: inline-block;
  padding: 3px 7px 4px;
  margin-right: 4px;
}


/* Case study */

.block--case-study-quote-block {
  background-color: #042d36;
  padding: 96px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.block--case-study-quote-block h1 {
  color: white !important;
  text-align: center;
}

.block--case-study-quote-block h1:before {
  content: "“";
  display: block;
  font-size: 100px;
  line-height: 10px;
  color: var(--lightblue);
}

.block--case-study-quote-block h2 {
  color: white !important;
  text-align: center;
  margin-top: 20px;
}



.block--case-study-details {
  min-height: 300px;
  width: 100%;
}

.block--case-study-stats .block--case-study-info 
.block--case-study-details {
  flex-direction: column;
}

.block--case-study-partners
.block--case-study-details 
.wp-post-image{
  max-width: 170px;
  max-height: 150px;
  height: auto;
  /* min-width: 150px; */
  width: auto;
}

.block--case-study-stats-area ul {
  list-style-type: none;
}
.block--case-study-stats-area {
  text-align: left;
  /* margin-left: 80px; */
}

.block--case-study-stats-area ul li h2 {
}

.block--case-study-stats-area h1[data-type=percentage]:after {
  content: "%";
}

.block--case-study-stats-area h1[data-type=amount]:before {
  content: "$";
}

.block--case-study-partners ul {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.block--case-study-partners ul li { 
  margin: 20px 40px;
}

.block--case-study-conclusion-block {
  display: flex;
  justify-content: center;
  background-color: var(--lightgray);
  padding: 80px 0 100px;
}

.block--case-study-conclusion-block
.block--case-study-conclusion-block-area {
  max-width: 1100px;
}

.block--case-study-conclusion-block
.block--case-study-conclusion-block-area h2 {
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.block--case-study-conclusion-block
.block--case-study-conclusion-block-area h1 {
  text-align: center;
  color: var(--lightblue);
}


.case-study-section h1,
.case-study-section h2 {
  margin: 0;
}

.case-study-section .row {
    width: 100%;
}


.case-study-section + .case-study-section {
  border-top: 1px solid rgb(225,225,225);
  padding-bottom: 96px;
}

.wp-link.button {
  font-size: 30px;
  line-height: 35px;
  font-family: var(--monospace);
}

.wp-link.button.active:before {
  max-height: 30px;
}


.block--case-related-projects {
  background-color: var(--lightgray);
  padding-top: 96px;
  padding-bottom: 64px;
}

.block--case-related-projects h2 {
  margin-left: 15px;
  padding-bottom: 32px;
}

.block--case-related-projects
.related-project {
  padding-right: 20px !important;
}

.related-project-container {
  background-color: white;
  padding: 32px;
  display: flex;
  margin-bottom: 32px;
}

.related-project-container .rp-image {
  width: 190px;
  height: 230px;
  background-size: cover;
  background-position: center center;
}

.rp-info {
  flex-shrink: 100;
  padding-left: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.rp-info .rp-excerpt{
  flex-grow: 1;
}

.rp-info .rp-excerpt p {
  padding: 0 !important;
}

.rp-info p {
  margin: 0;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}

.rp-button a.button::before {
  z-index: 0;
}

.case-study-outputs ul {
  margin: 0;
}

ul.case-study-outputs {
  margin-top: 9px;
}
a.case-study-output-link {
  display: inline-block;
  align-items: center;
  padding: 4px 8px;
  border: 2px solid white;
  border-radius: 5px;
  color: white;
  font-family: var(--sans-serif);
  font-size: 20px;
  line-height: 35px;
  font-weight: bold;
  margin: 4px;
  margin-left: 0;
}

a.case-study-output-link::after {
  content: '';
  background-image: url(../../assets/image/output-link.svg);
  width: 23px;
  height: 23px;
  margin-left: 4px;
  display: inline-block;
  vertical-align: center;
  background-size: cover;
}

a.case-study-output-link:hover {
  background-color: var(--lightblue);
  color: var(--darkblue);
  border-color: var(--lightblue);
}

a.case-study-output-link:hover::after {
  background-image: url(../../assets/image/output-link-hover.svg);
}

.case-study .case-study--hero h1, 
.case-study .case-study--hero h2 {
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .case-study .case-study--meta {
    height: auto;
    max-height: none;
  }

  .case-study-meta-container {
    flex-direction: column;
  }

  .case-study-tags-list ul {
    margin-top: 0;
    margin-bottom: 16px;
  }

  .block--case-study-partners ul li {
    margin: 10px;
  }

  .block--case-study-partners .block--case-study-details .wp-post-image {
    max-width: 130px;
    max-height: 150px;
  }

  .case-study .case-study--hero {
    min-height: 250px;
    height: auto;
    padding-top: 32px;
  }

  .case-study .case-study-image {
    height: 235px;
  }
  
  .block--case-study-quote-block {
    padding: 32px;
  }

  .block--case-study-quote-block-area {
    width: 100%;
  }
  .case-study 
  .block--case-study-quote-block-area
  h1 {
    margin: 50px 0 0;
  }

  .case-study .case-study-body .case-study-pullquote {
    display: none;
  }

  .case-study
  .case-study--hero {
    padding: 32px 32px 0;
    justify-content: flex-end;
  }

  .case-study .case-study--hero h1, 
  .case-study .case-study--hero h2 {
    width: 100%;
    padding: 0;
    margin: 0;
    padding-bottom: 16px;
    text-align: left;
  }

  .case-study .case-study--hero h2 {
    padding-bottom: 64px;
  }

  .case-study .case-study-body .case-study-content {
    width: 100%;
  }

}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .case-study .case-study-body .case-study-content {
    width: 100% !important;
  }
  .case-study-pullquote {
    display: none !important;
  }
}